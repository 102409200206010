<template>
  <div class="container">
    <div class="row pt-4 pb-4 text-center text-md-left">
      <div class="col-12">
        <h4 class="text-white">Contatti</h4>
      </div>
      <div class="col-12 d-block">
        <div class="row">
          <div class="col-12 col-md-4">
            <div class="row">
              <div class="col-12 pb-1">
                <h6 class="text-up">e-mail</h6>
              </div>
              <div class="col-12 pb-1">
                <h5 class="text-white">
                  <a href="mailto:polizza.vaccinatori@consulbrokers.it">
                    polizza.vaccinatori@consulbrokers.it
                  </a>
                </h5>
              </div>
            </div>
            <div class="row">
              <div class="col-12 pb-1 mt-">
                <h6 class="text-up">Telefono</h6>
              </div>
              <div class="col-12">
                <h5 class="text-white">
                  <a href="tel:+393773595366">+39 377 359 5366</a>
                </h5>
                <h6 class="white--text">
                  Il numero sarà attivo dal lunedì al venerdì nelle seguenti
                  fasce orarie: 9-13 15-18
                </h6>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-2"></div>
          <div class="col-12 col-md-6">
            <div class="row">
              <div class="col-12 pb-1">
                <h6 class="text-up">Indirizzo</h6>
              </div>
              <div class="col-12">
                <h5 class="text-white">
                  <a
                    href="https://maps.google.com/?q=Piazza della Vittoria 14/a CB"
                    target="_blank"
                    rel="nofollow"
                  >
                    Piazza della Vittoria 14/a (CB) | Consulbrokers - Uff.
                    Campobasso
                  </a>
                </h5>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-12 pb-1">
                <h6 class="text-up">Sedi operative</h6>
              </div>
              <div class="col-12">
                <div class="white--text">
                  <h5 class="text-white d-inline m-0">
                    Napoli
                  </h5>
                  –
                  <h6 class="d-inline white--text m-0">
                    Via Mergellina, 2 - 80122, Napoli (NA)
                  </h6>
                </div>
                <div class="white--text">
                  <h5 class="text-white d-inline m-0">
                    Potenza
                  </h5>
                  –
                  <h6 class="d-inline white--text m-0">
                    Viale Marconi, 90 - 85100, Potenza (PZ)
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div
            class="col-12 text-white text-center mb-3 "
            style="font-weight: 700; display: none"
          >
            Il servizio di consulenza telefonica non sarà attivo dal 13 al 21
            agosto 2022
          </div>
        </div>
        <div class="row justify-content-center pt-4">
          <!-- <div class="8 pb-3">
            <h6 class="white--text">
              Il numero sarà attivo dal lunedì al venerdì nelle seguenti fasce
              orarie: 9-13 15-18
            </h6>
          </div> -->
          <div class="col-8 py-2 center">
            <h6>
              CB CONSULBROKERS S.P.A&nbsp;&nbsp;—&nbsp;&nbsp;C.SO DI PORTA
              NUOVA, 16&nbsp;&nbsp;—&nbsp;&nbsp;20121 MILANO P.I. 12994080963
              <br />
              REA: MI2697300&nbsp;&nbsp;—&nbsp;&nbsp;RUI: B000736338 -
              <a
                href="https://ruipubblico.ivass.it/rui-pubblica/ng/#/workspace/registro-unico-intermediari"
              >
                Consulta il registro
              </a>
              <br />
              <a href="mailto:consulbrokerscb@legalmail.it">
                consulbrokerscb@legalmail.it
              </a>
              &nbsp;&nbsp;—&nbsp;&nbsp;
              <a href="tel:3457353364">3457353364</a>
              &nbsp;&nbsp;—&nbsp;&nbsp;CAPITALE IN BILANCIO: 2.171.714,30 EURO
              <br />
              SOTTOPOSTO ALLA VIGILANZA IVASS
              <br />
              LA SOCIETÀ È ABILITATA ALL’ESERCIZIO DELL’ATTIVITÀ IN ITALIA
              <br />
              <a
                href="https://www.consulbrokers.it/Parte-generale-modello-231/"
                target="_blank"
              >
                PARTE GENERALE MODELLO 231
              </a>
              -
              <a
                href="https://www.consulbrokers.it/CONSULBROKERS%20SPA%20-%20MOG%20Parte%20speciale.pdf"
              >
                PARTE SPECIALE 231
              </a>
            </h6>
          </div>
          <div
            style="font-size: 12px;"
            class="col-md-8 text-white text-center pb-2"
          >
            Informazioni per l’assicurato/contraente. Qualora sussista una
            doglianza attinente al comportamento dell’intermediario, vi è la
            facoltà di inoltrare reclamo per iscritto ad CB Consulbrokers S.P.A,
             Ufficio Reclami Corso di Porta Nuova 16 , Milano (oppure a
            mezzo e-mail, specificando nell'oggetto "RECLAMO", all'indirizzo:
            consulbrokerscb@legalmail.it. La normativa concede 45 giorni entro i
            quali fornire una risposta
          </div>
          <div class="col-8 pb-1 pt-2 center">
            <h6>
              <a
                href="http://www.consulbrokers.it/azienda/privacy-policy"
                target="_blank"
              >
                PRIVACY
              </a>
              -
              <a
                href="https://www.consulbrokers.it/gestione-reclami.pdf"
                target="_blank"
              >
                GESTIONE RECLAMI
              </a>
            </h6>
          </div>
        </div>
      </div>
      <div class="col-12 d-md-none d-lg-none">
        <div class="row">
          <div class="col-12 col-md-2">
            <div class="row">
              <div class="col-12 pb-3 pt-3">
                <h5 class="text-up company">
                  <span>CONSULBROKERS</span>
                </h5>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-2">
            <div class="row">
              <div class="col-12 pb-1">
                <h6 class="text-up">e-mail</h6>
              </div>
              <div class="col-12 pb-1">
                <h5 class="text-white">
                  <a href="mailto:polizza.vaccinatori@consulbrokers.it">
                    polizza.vaccinatori@consulbrokers.it
                  </a>
                </h5>
              </div>
              <div class="col-12 pb-1">
                <h6>
                  <a
                    href="http://www.consulbrokers.it/azienda/privacy-policy"
                    target="_blank"
                  >
                    Privacy
                  </a>
                  -
                  <a
                    href="https://www.consulbrokers.it/gestione-reclami.pdf"
                    target="_blank"
                  >
                    Gestione Reclami
                  </a>
                </h6>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-2">
            <div class="row">
              <div class="col-12 pb-1">
                <h6 class="text-up">Telefono</h6>
              </div>
              <div class="col-12 pb-1">
                <h5 class="text-white">
                  <a href="tel:+39377 3595366">+39 377 359 5366</a>
                </h5>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-4">
            <div class="row">
              <div class="col-12 pb-1">
                <h6 class="text-up">Indirizzo</h6>
              </div>
              <div class="col-12 pb-1">
                <h5 class="text-white">
                  <a
                    href="https://maps.google.com/?q=Piazza della Vittoria 14/a CB"
                    target="_blank"
                    rel="nofollow"
                  >
                    Piazza della Vittoria 14/a (CB) | Consulbrokers - Uff.
                    Campobasso
                  </a>
                </h5>
              </div>
            </div>
          </div>
        </div>
        <div class="row justify-content-center pt-2">
          <div class="col-12 pb-3">
            <h6>
              Il numero sarà attivo dal lunedì al venerdì nelle seguenti fasce
              orarie: 9-13 15-18
            </h6>
          </div>
          <div class="col-12 pb-1 center">
            <h6>
              CONSULBROKERS S.P.A. - C.SO DI PORTA NUOVA, 16 - 20121 MILANO P.I.
              12994080963 - ISCRIZIONE SEZIONE B 000736338 -
              <a href="mailto:CONSULBROKERSSPA@LEGALMAIL.IT">
                CONSULBROKERSSPA@LEGALMAIL.IT
              </a>
              - PZ 73178 - CAPITALE IN BILANCIO: 2.171.714,30 EURO
              <a
                href="https://www.consulbrokers.it/Modello%20Parte%20Generale.pdf"
                target="_blank"
              >
                PARTE GENERALE MODELLO 231
              </a>
              -
              <a
                href="https://www.consulbrokers.it/CONSULBROKERS%20SPA%20-%20MOG%20Parte%20speciale.pdf"
              >
                PARTE SPECIALE 231
              </a>
            </h6>
          </div>
          <div class="col-12 pb-1 pt-2 center">
            <h6>
              <a
                href="http://www.consulbrokers.it/azienda/privacy-policy"
                target="_blank"
              >
                PRIVACY
              </a>
              -
              <a
                href="https://www.consulbrokers.it/gestione-reclami.pdf"
                target="_blank"
              >
                GESTIONE RECLAMI
              </a>
            </h6>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.terms {
  color: #a7adb1 !important;
}
.center {
  text-align: center;
}
</style>
