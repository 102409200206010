import { createApp } from 'vue';
import firebase from 'firebase';
import axios from 'axios';
import VueAxios from 'vue-axios';
import App from './App.vue';
import store from './store';
import router from './router';
import 'normalize.css/normalize.css';

const config = {
  apiKey: 'AIzaSyBojjR5XaYeEzskO3-ZT_jXIxEjuahLZJg',
  authDomain: 'cb-amtrust.firebaseapp.com',
  projectId: 'cb-amtrust',
  databaseURL: 'https://cb-amtrust.firebaseio.com',
  storageBucket: 'cb-amtrust.appspot.com',
  messagingSenderId: '798445891791',
  appId: '1:798445891791:web:f0f24695d4ac48eee147ba',
  measurementId: 'G-WYVK6CP6V7',
};

// Init
firebase.initializeApp(config);

createApp(App).use(store).use(router).use(VueAxios, axios)
  .mount('#app');
