<template>
  <div class="white mb-4">
    <div class="container">
      <div class="row pt-2 pb-2 pt-md-4 pb-md-4">
        <div class="
        pb-md-0
        pt-md-0
        col-12
        text-center
        text-md-right
        d-flex align-items-center
        justify-content-md-end
        justify-content-center"
        >
          <div class="row no-gutters">
            <div class="col">
              <img alt="UIM Logo" src="@/assets/AmTrust.jpg" width="200">
            </div>
            <div class="col">
              <img alt="UIM Logo" src="@/assets/Consulbrokers.jpg" width="200">
            </div>
            <div class="col">
              <img alt="UIM Logo" src="@/assets/FNOMCeO.jpg" width="200">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .white{
    background: white;
  }
</style>
