<template>
  <div class="container">
    <div class="row align-items-stretch">
      <div class="col-12 col-md-6 d-flex align-items-center pb-md-0 pb-5">
        <div class="row">
          <div class="col-12">
            <button class="button white pt-3 mt-1 mt-md-0 mb-4" @click="change()">
              Sottoscrivi polizza
            </button>
          </div>
          <div class="col-12">
            <h2 class="text-white mb-4">
              Rinnovo Condizioni di Garanzia
            </h2>
          </div>
          <div class="col-12">
            <div class="bb"></div>
          </div>
          <div class="col-12">
            <h4 class="text-white pt-4">
              Contraente: FNOMCeO <br>
              Assicurato: il singolo medico aderente
            </h4>
          </div>
          <div class="col-12">
            <h4 class="text-white pt-1">
              Garanzia: RCT derivante al Medico assicurato
              dall’attività di vaccinazione Covid ivi compreso c.d. “Colpa Grave”
            </h4>
          </div>
          <div class="col-12">
            <h4 class="text-white pt-1">
              Durata: 1 anno (Retroattività ZERO) <br>
              Premio annuale: € 100 <br> Massimale: € 1.000.000
            </h4>
          </div>
          <div class="col-12">
            <h5 class="text-white pt-2 grey" style="text-align: justify">
              Al termine  dell'attività vaccinale l'assicurato potrà
              attivare la postuma decennale sempre al costo di € 100 oppure
              stipulare una polizza con AmTrust Assicurazioni nel qual
              caso la postuma sarà automaticamente inclusa.
              La postuma serve a garantire l'assicurato anche per richieste
              di risarcimento che dovessero
              pervenire successivamente alla data di scadenza della
              polizza ma comunque relative al periodo di
              assicurazione.
            </h5>
          </div>
          <div class="col-12">
            <h5 class="text-white pt-2 grey" style="text-align: justify">
              La polizza è valida anche per i medici in stato di quiescenza
              purché iscritti all’albo dei medici chirurgi e odontoiatri
            </h5>
          </div>
          <div class="col-12 bg-grey">
            <h5 class="pb-1 pt-4">
              <strong>DECORRENZA</strong> <br>
              Il cliente ha facoltà, qualora richiesto, di scegliere di retrodatare
              la propria garanzie e scegliere una data di decorrenza diversa da quella
              del giorno di pagamento mediante le seguenti opzioni:<br><br>
              <p>
                <strong>Opzione 1</strong><br>
                Per una data di decorrenza fino a 30 giorni il cliente può selezionare la
                preferenza nell’apposito form di compilazione. Questa scelta non comporta
                nessuna maggiorazione del premio assicurativo.
              </p>
              <p>
                <strong>Opzione 2</strong><br>
                Per coloro che volessero richiedere una retrodatazione superiore ai 30 giorni
                si prega di prendere contatto diretto con i nostri uffici ai recapiti segnalati
                nella apposita sezione “Contatti”.
              </p>
            </h5>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <app-box/>
        <div class="col-12 pt-3">
          <h5 class="text-white pt-2 grey">
            Documenti contrattuali<br><br>
            <ul>
              <li>
                <a href="SetInformativo_MLP_072020.pdf" target="_blank">
                  Contratto di Assicurazione Responsabilità Professionale del Medico
                </a>
              </li>
              <li>
                <a href="Appendice_Variazione generica_MLP_Attività_Vaccinale.pdf"
                   target="_blank">
                  Appendice al contratto di Assicurazione Responsabilità Professionale del Medico
                </a>
              </li>
              <li>
                <a href="MODULO_DENUNCIA_FNOMCEO.pdf" target="_blank">
                  Modulo denuncia sinistri (da scaricare
                  e inviare come allegato alla email sottostante)
                </a>
              </li>
              <li>
                <a href="documentazione-pre-contrattuale-IVASS.zip" target="_blank">
                  Documentazione pre-contrattuale IVASS
                </a>
              </li>
              <li>
                <a href="0082_Addendum Nuovo AQ_RC_Fnomceo.pdf" target="_blank">
                  Addendum retroattività
                </a>
              </li>
              <li>
                <a href="Addendum_convenzione_0082_Medici_vaccinatori_signed.pdf" target="_blank">
                  Addendum estensione di garanzia
                </a>
              </li>
            </ul>
          </h5>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.button {
  background: white;
  border: 0;
  border-radius: 4px;
  outline: none;
  text-transform: uppercase;
  padding: 13px;
  font-weight: bold;
}
h4{
  color: #A7ADB1 !important;
}
.bb{
  height: 1px;
  width: 150px;
  border-bottom: 1px solid #FCFCFC;
}
.bg-grey{
  color: white;
  margin: 16px 0;
  background: #A7ADB1;
  border-radius: 16px;
  h5{
    text-align: justify;
    font-weight: 400 !important;
    color: white !important;
  }
}
ul{
  padding-left: 16px;
}
</style>

<script>
import AppBox from '@/components/box-renewal.vue';

export default {
  name: 'Home',
  components: {
    AppBox,
  },
  methods: {
    change() {
      this.$router.push({name: 'home'})
    }
  }
};
</script>
