<template>
  <div class="box">
    <div class="b box-2">
      <div class="row h-100 no-gutters">
        <div class="col-12 h-100">
          <div class="row">
            <div class="col-12">
              <h4 class="pt-2 pr-4 pl-4 pb-0 m-0">Dati anagrafici</h4>
            </div>
            <div class="col-12">
              <form @submit.prevent="send" class="row pr-4 pl-4 pt-1 pb-2">
                <div class="col-12 col-md-6 pt-3">
                  <app-input placeholder="Nome"
                             v-model="data.firstName" required></app-input>
                </div>
                <div class="col-12 col-md-6 pt-3">
                  <app-input placeholder="Cognome"
                             v-model="data.lastName" required></app-input>
                </div>
                <div class="col-12 col-md-4">
                  <div class="mt-3 mt-md-4">Data di nascita:</div>
                </div>
                <div class="col-12 col-md-8 pt-3">
                  <app-input
                    type="date"
                    v-model="data.birthDate"
                    onfocus="this.type='date'"
                    placeholder="dd-mm-yyyy"
                    required
                  ></app-input>
                </div>
                <div class="col-12 col-md-6 pt-3">
                  <app-input placeholder="Codifice Fiscale"
                             v-model="data.fiscalCode" required></app-input>
                </div>
                <div class="col-12 col-md-6 pt-3">
                  <select v-model="data.sex" required>
                    <option disabled selected value>Sesso*</option>
                    <option value="M">M</option>
                    <option value="F">F</option>
                  </select>
                </div>
                <div class="col-12 col-md-6 pt-3">
                  <select v-model="data.toponimo" required>
                    <option disabled selected value>Toponimo*</option>
                    <option value="Corso">Corso</option>
                    <option value="Piazza">Piazza</option>
                    <option value="Strada">Strada</option>
                    <option value="Via">Via</option>
                    <option value="Viale">Viale</option>
                    <option value="Altro">Altro</option>
                  </select>
                </div>
                <div class="col-12 col-md-6 pt-3">
                  <app-input placeholder="Indirizzo residenza"
                             v-model="data.address" required></app-input>
                </div>
                <div class="col-12 col-md-6 pt-3">
                  <app-input placeholder="Civico residenza"
                             v-model="data.addressNumber" required></app-input>
                </div>
                <div class="col-12 col-md-6 pt-3">
                  <select v-model="data.addressProvince" @change="loadCity" required>
                    <option disabled selected value>Provincia residenza*</option>
                    <option :value="elm.data().Provincia" v-bind:key="index"
                      v-for="(elm, index) in item.province">
                      {{elm.data().Nome}}
                    </option>
                  </select>
                </div>
                <div class="col-12 col-md-6 pt-3">
                  <select v-model="data.addressCity" @change="loadZip" required>
                    <option disabled selected value>Comune residenza*</option>
                    <option disabled selected value v-if="item.comuni.length <= 0">Seleziona la provincia</option>
                    <option :value="elm.data().Comune" v-bind:key="index"
                            v-for="(elm, index) in item.comuni">
                      {{elm.data().Comune}}
                    </option>
                  </select>
                </div>
                <div class="col-12 col-md-6 pt-3">
                  <select v-model="data.addressZip" required>
                    <option disabled selected value>CAP residenza*</option>
                    <option disabled selected value v-if="item.zip.length <= 0">Seleziona il comune</option>
                    <option :value="elm.data().Cap" v-bind:key="index"
                            v-for="(elm, index) in item.zip">
                      {{elm.data().Cap}}
                    </option>
                  </select>
                </div>
                <div class="col-12 col-md-6 pt-3">
                  <app-input placeholder="Nazione residenza"
                             v-model="data.addressNation" required></app-input>
                </div>
                <div class="col-12 col-md-6 pt-3">
                  <select v-model="data.birthProvince" @change="loadCityB"  required>
                    <option disabled selected value>Provincia di nascita*</option>
                    <option :value="elm.data().Provincia" v-bind:key="index"
                      v-for="(elm, index) in item.province">
                        {{elm.data().Nome}}
                    </option>
                  </select>
                </div>
                <div class="col-12 col-md-6 pt-3">
                  <select v-model="data.birthCity" required>
                    <option disabled selected value>Comune di nascita*</option>
                    <option :value="elm.data().Comune" v-bind:key="index"
                            v-for="(elm, index) in item.comuniB">
                      {{elm.data().Comune}}
                    </option>
                  </select>
                </div>
                <div class="col-12 col-md-6 pt-3">
                  <select v-model="data.decorrenza" required>
                    <option disabled selected value>Seleziona decorrenza*</option>
                    <option value="0">Oggi</option>
                    <option value="1">1 giorno fa</option>
                    <option value="2">2 giorni fa</option>
                    <option value="3">3 giorni fa</option>
                    <option value="4">4 giorni fa</option>
                    <option value="5">5 giorni fa</option>
                    <option value="6">6 giorni fa</option>
                    <option value="7">7 giorni fa</option>
                    <option value="8">8 giorni fa</option>
                    <option value="9">9 giorni fa</option>
                    <option value="10">10 giorni fa</option>
                    <option value="11">11 giorni fa</option>
                    <option value="12">12 giorni fa</option>
                    <option value="13">13 giorni fa</option>
                    <option value="14">14 giorni fa</option>
                    <option value="15">15 giorni fa</option>
                    <option value="16">16 giorni fa</option>
                    <option value="17">17 giorni fa</option>
                    <option value="18">18 giorni fa</option>
                    <option value="19">19 giorni fa</option>
                    <option value="20">20 giorni fa</option>
                    <option value="21">21 giorni fa</option>
                    <option value="22">22 giorni fa</option>
                    <option value="23">23 giorni fa</option>
                    <option value="24">24 giorni fa</option>
                    <option value="25">25 giorni fa</option>
                    <option value="26">26 giorni fa</option>
                    <option value="27">27 giorni fa</option>
                    <option value="28">28 giorni fa</option>
                    <option value="29">29 giorni fa</option>
                    <option value="30">30 giorni fa</option>
                  </select>
                </div>
                <div class="col-12 col-md-6 pt-3">
                  <app-input placeholder="E-Mail" type="email"
                             v-model="data.email" required></app-input>
                </div>
                <div class="col-12 col-md-6 pt-3">
                  <app-input placeholder="Cellulare"
                             v-model="data.phone" required></app-input>
                </div>
                <div class="col-12 col-md-12 pt-3">
                  <select v-model="data.province" required>
                    <option disabled selected value>Provincia albo iscrizione*</option>
                    <option :value="elm.data().Provincia" v-bind:key="index"
                      v-for="(elm, index) in item.province">
                      {{elm.data().Nome}}
                    </option>
                  </select>
                </div>
                <div class="col-12 col-md-12 pt-3">
                  <app-input type="number"
                     placeholder="Iscrizione all’albo (numero tra 4 e 6 cifre)"
                     v-model="data.albo" required></app-input>
                </div>
                <div class="col-12 col-md-12 pt-3">
                  <div class="row no-gutters justify-center align-center">
                    <input type="checkbox" required v-model="item.consent"
                           class="mt-3 mr-2">
                    <div class="col">
                      <label class="small">Dichiaro di essere iscritto
                        all’Albo dei Medici Chirurgi e degli Odontoiatri</label>
                    </div>
                  </div>
                  <div class="row no-gutters justify-center align-center">
                    <input type="checkbox" required
                           class="mt-3 mr-2">
                    <div class="col">
                      <label class="small">Dichiaro di aver scaricato correttamente,
                        visionato e accettato la documentazione pre-contrattuale IVASS</label>
                    </div>
                  </div>
                </div>
                <div class="col-12 pt-1" v-if="item.err">
                  <div class="center">
                    <span class="err">
                      Il suo codice fiscale risulta già essere in copertura.
                      Per maggiori informazioni ci contatti direttamente.
                    </span>
                    <br>
                  </div>
                </div>

                <div class="row no-gutters" style="width: 100%">
                  <div id="paypal" class="hidden"></div>
                  <div class="col-12 col-md-12 pr-3 pl-3"
                       id="smart-button-container">
                    <div id="paypal-button-container"></div>
                  </div>
                  <div class="col-12 col-md-12 pr-3 pl-3 pt-1" v-if="!item.error"
                       :class="{'hidden': data.type}">
                    <button class="button pt-3 mt-1 mt-md-0">
                      <h5 class="m-0" v-if="!item.loadingC"
                          @click="item.type = true">Invia i dati e paga con carta</h5>
                      <div class="spinner-grow"
                           style="width: 1rem; height: 1rem;" v-else role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    </button>
                  </div>
                  <div class="col-12 col-md-12 pr-3 pl-3 pt-1" v-if="!item.error">
                    <button class="button pt-3 mt-1 mt-md-0">
                      <h5 class="m-0" v-if="!item.loadingB"
                          @click="item.type = false"> Paga con bonifico</h5>
                      <div class="spinner-grow"
                           style="width: 1rem; height: 1rem;" v-else role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    </button>
                  </div>
                  <div class="col-12 col-md-12 pr-3 pl-3 pt-1" v-if="item.error">
                    <div class="button red pt-3 mt-1 mt-md-0">
                      <h5 class="m-0">
                        Per i maggiori di 80 anni si invita al contatto diretto
                        <br><br>
                        <a href="tel:+393773595366">+39 377 359 5366</a><br><br>
                        <a href="mailto:polizza.vaccinatori@consulbrokers.it">polizza.vaccinatori@consulbrokers.it</a> <br>
                      </h5>
                    </div>
                  </div>
                </div>
                <div class="col-12 small pt-2">
                  * Il pagamento con carta è effettuato senza commissioni
                </div>
                <div class="col-12 small pt-2">
                  <img src="@/assets/credit-card-icons.png" width="150">
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import firebase from 'firebase';
import axios from 'axios';
import router from '@/router';
import { watch, reactive } from 'vue';
import AppInput from '@/components/input.vue';

export default {
  name: 'Box',
  components: {
    AppInput,
  },
  watch:{
    'data.birthDate'(val){
      this.age();
    },
  },
  setup() {
    const item = reactive({
      index: 0,
      consent: false,
      loadingB: false,
      loadingC: false,
      err: false,
      type: false,
      province: [],
      comuni: [],
      comuniB: [],
      zip: [],
      zipB: [],
      error: false
    });
    const nexi = reactive({
      alias: '',
    });
    const data = reactive({
      firstName: '',
      lastName: '',
      birthDate: '',
      fiscalCode: '',
      sex: '',
      decorrenza: '',
      toponimo: '',
      address: '',
      addressNumber: '',
      addressCity: '',
      addressProvince: '',
      addressZip: '',
      addressNation: '',
      birthProvince: '',
      birthCity: '',
      email: '',
      phone: '',
      province: '',
      albo: '',
      type: false,
      confirmed: false,
      declaration: true,
    });

    firebase.firestore().collection('province').orderBy('Nome', 'asc').get()
      .then((res) => {
        item.province = res.docs;
      });

    function age() {
      item.error = false;
      const today = new Date();
      const birthDate = new Date(data.birthDate);
      let age = today.getFullYear() - birthDate.getFullYear();
      const m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      if(age >= 80) {
        item.error = true
        console.log(age);
      }
      return age;
    }
    function loadCity() {
      firebase.firestore().collection('comuni').where('Provincia','==',data.addressProvince).orderBy('Comune', 'asc').get()
          .then((res) => {
            item.zip = []
            data.addressCity = ''
            data.addressZip = ''
            item.comuni = _.uniqBy(res.docs, function (e) {
              return e.data().Comune;
            });
          });
    }
    function loadZip() {
      firebase.firestore().collection('comuni')
          .where('Provincia','==',data.addressProvince)
          .where('Comune','==',data.addressCity).orderBy('Cap', 'asc').get()
          .then((res) => {
            item.zip = res.docs;
            console.log(res.docs)
          });
    }
    function loadCityB() {
      firebase.firestore().collection('comuni').where('Provincia','==',data.birthProvince).orderBy('Comune', 'asc').get()
          .then((res) => {
            data.birthCity = ''
            item.comuniB = _.uniqBy(res.docs, function (e) {
              return e.data().Comune;
            });
          });
    }
    function select(index) {
      item.index = index;
    }
    function verify() {
      axios.post('https://application.fnomceo.it/Fnomceo/controllaProfessionista.service', {
        codiceFiscale: data.fiscalCode,
        nome: data.firstName,
        cognome: data.lastName,
      }).then(() => {
      });
    }
    function sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    }
    async function send() {
      item.err = false;
      // loader button
      if (!item.type) {
        item.loadingB = true;
      } else {
        item.loadingC = true;
      }
      await sleep(500);
      data.fiscalCode = data.fiscalCode.toUpperCase()
      // check fiscal code
      const check = await firebase.firestore().collection('clients')
        .where('fiscalCode', '==', data.fiscalCode)
        .where('confirmed', '==', true)
        .get();
      if (check.docs.length > 0) {
        item.err = true;
        item.loadingB = false;
        item.loadingC = false;
        return;
      }
      // display button
      if (!item.type) {
        data.confirmed = true;
        data.type = false;
      } else {
        data.type = true;
      }
      // verify(data.fiscalCode);
      /* eslint-disable no-param-reassign */
      data.created_at = firebase.firestore.Timestamp.fromDate(new Date());
      data.updated_at = firebase.firestore.Timestamp.fromDate(new Date());
      firebase.firestore().collection('clients').add(data)
        .then((res) => {
          if (item.type) {
            document.getElementById('paypal').innerHTML = res.id;
            window.initPayPalButton();
            item.loadingC = false;
          } else {
            router.push('success');
            item.loadingB = false;
          }
        })
        .catch((err) => {
          console.log(err);
          item.loadingC = false;
          item.loadingB = false;
        });
    }
    return {
      select,
      send,
      verify,
      age,
      nexi,
      data,
      item,
      loadCity,
      loadZip,
      loadCityB,
    };
  },
};
</script>

<style lang="scss" scoped>
  @import "~@/style.scss";
  .text{
    text-align: justify;
    line-height: 1.35rem;
  }
  .red {
    cursor: default;
    height: inherit;
    background: #fda12d;
    border: 2px solid #fda12d;
  }
  .box {
    display: flex;
    flex-direction: column;
  }
  .hide{
    display: none;
  }
  .b {
    padding: 16px;
    margin-top: 2px;
    display: flex;
    flex-direction: column;
  }

  .box-1 {
    height: 60px;
    justify-content: center;
  }

  .box-2 {
    flex: 1;
    justify-content: start;
  }
  .small{
    line-height: 1.35rem;
  }
  .hidden{
    display: none;
  }
  select{
    width: 100%;
    padding: 0 16px;
    border-radius: 4px;
    font-size: 14px;
    text-align: left;
    height: 42px;
    border: 1px solid #CCCCCC;
    background: #FCFCFC;
    box-shadow: none;
    outline: none;
    option:disabled {
      color: black;
    }
  }

</style>
