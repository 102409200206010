import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';
import Renewal from '../views/renewal.vue';
import Paid from '../views/paid.vue';
import Success from '../views/success.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/paid/:id',
    name: 'paid',
    component: Paid,
  },
  {
    path: '/success',
    name: 'success',
    component: Success,
  },
  {
    path: '/renewal',
    name: 'renewal',
    component: Renewal,
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/',
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
