<template>
  <app-layout>
    <router-view/>
  </app-layout>
</template>

<script>
import AppLayout from '@/layouts/default.vue';

export default {
  name: 'Home',
  components: {
    AppLayout,
  },
};
</script>

<style lang="scss">
$grey: #A7ADB1;

html, body, #app{
  height: 100%;
  margin: 0;
  padding: 0;
  letter-spacing: 0.04rem;
  font-family: 'Raleway', sans-serif !important;
  a{
    color: white;
    text-decoration: none;
  }
  .grey{
    color: $grey !important;
  }
  h1{
    margin: 0;
    color: #FCFCFC;
    font-size: 48px;
    font-weight: 600;
  }
  h2{
    font-size: 30px;
    font-weight: 600;
  }
  h3{

  }
  h4{
    font-size: 20px;
    font-weight: 400;
  }
  h5{
    font-size: 14px;
    font-weight: 300;
  }
  h6{
    color: $grey;
    font-size: 12px;
  }
  .text-up{
    text-transform: uppercase;
  }
  .company{
    font-weight: 500;
    color: $grey;
  }
  .hidden{
    visibility: hidden;
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity .5s
  }

  .fade-enter,
  .fade-leave-active {
    opacity: 0
  }
}
</style>
