<template>
  <div class="container">
    <div class="row align-items-stretch">
      <div class="col-12 col-md-6 d-flex align-items-center pb-md-0 pb-5">
        <div class="row">
          <div class="col-12">
            <h2 class="text-white mb-4">
              Condizioni di Garanzia
            </h2>
          </div>
          <div class="col-12">
            <div class="bb"></div>
          </div>
          <div class="col-12">
            <h4 class="text-white pt-4">
              Contraente: FNOMCeO <br>
              Assicurato: il singolo medico aderente
            </h4>
          </div>
          <div class="col-12">
            <h4 class="text-white pt-1">
              Garanzia: RCT derivante al Medico assicurato dall’attività di vaccinazione Covid
            </h4>
          </div>
          <div class="col-12">
            <h4 class="text-white pt-1">
              Durata: 1 anno (Retroattività ZERO) <br>
              Premio annuale: € 100 <br> Massimale: € 1.000.000
            </h4>
          </div>
          <div class="col-12">
            <h5 class="text-white pt-2 grey" style="text-align: justify">
              Al termine  dell'attività vaccinale l'assicurato potrà
              attivare la postuma decennale sempre al costo di € 100 oppure
              stipulare una polizza con AmTrust Assicurazioni nel qual
              caso la postuma sarà automaticamente inclusa.
              La postuma serve a garantire l'assicurato anche per richieste
              di risarcimento che dovessero
              pervenire successivamente alla data di scadenza della
              polizza ma comunque relative al periodo di
              assicurazione.
            </h5>
          </div>
          <div class="col-12">
            <h5 class="text-white pt-2 grey">
              Documenti contrattuali<br><br>
              <ul>
                <li>
                  <a href="SetInformativo_MLP_072020.pdf" target="_blank">
                    Contratto di Assicurazione Responsabilità Professionale del Medico
                  </a>
                </li>
                <li>
                  <a href="Appendice_Variazione generica_MLP_Attività_Vaccinale.pdf"
                     target="_blank">
                    Appendice al contratto di Assicurazione Responsabilità Professionale del Medico
                  </a>
                </li>
                <li>
                  <a href="MODULO_DENUNCIA_FNOMCEO.pdf" target="_blank">
                    Modulo denuncia sinistri (da scaricare
                    e inviare come allegato alla email sottostante)
                  </a>
                </li>
                <li>
                  <a href="documentazione-pre-contrattuale-IVASS.zip" target="_blank">
                    Documentazione pre-contrattuale IVASS
                  </a>
                </li>
                <li>
                  <a href="0082_Addendum Nuovo AQ_RC_Fnomceo.pdf" target="_blank">
                    Addendum retroattività
                  </a>
                </li>
                <li>
                  <a href="Addendum_convenzione_0082_Medici_vaccinatori_signed.pdf" target="_blank">
                    Addendum estensione di garanzia
                  </a>
                </li>
              </ul>
            </h5>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="box h-100">
          <div class="b box-2">
            <div class="row h-100 no-gutters">
              <div class="col-12 h-100">
                <div class="row align">
                  <div class="col-12">
                    <h3 class="pt-2 pr-4 pl-4 pb-3 m-0">Richiesta Effettuata</h3>
                  </div>
                  <div class="col-12">
                    <div class="success pt-2 pr-4 pl-4 pb-0 m-0">Grazie per aver completato la tua
                      richiesta per la copertura assicurativa della responsabilità
                      civile professionale
                      relativa alla attività vaccinale alle condizioni di cui alla Convenzione
                      n. 0082/2021 tra la FNOMCeO e la Compagnia Am Trust Assicurazioni SpA.</div>
                  </div>
                  <div class="col-12">
                    <div class="success pt-2 pr-4 pl-4 pb-0 m-0">Ti preghiamo, per completare
                      la procedura, di effettuare un bonifico del valore di 100 €
                      al seguente IBAN:</div>
                  </div>
                  <div class="col-12">
                    <div class="success pt-2 pr-4 pl-4 pb-0 m-0">
                      <strong>CONSULBROKERS BNL CAMPOBASSO
                      <br>
                        IT49D0100503403000000000941</strong>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="success pt-2 pr-4 pl-4 pb-0 m-0">
                      Le garanzie  di polizza sono operative dalle ore
                      24:00 del giorno  indicato in  fase di compilazione
                      della richiesta, salvo buon fine del pagamento suddetto.
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="success pt-2 pr-4 pl-4 pb-0 m-0">Nei prossimi giorni riceverà
                      copia del contratto che, debitamente sottoscritto, ci restituirà
                      stesso mezzo o a mezzo posta raccomandata all’indirizzo indicato.</div>
                  </div>
                  <div class="col-12">
                    <div class="success pt-2 pr-4 pl-4 pb-0 m-0">Per ogni altra necessità
                      o chiarimento il nostro staff rimane a sua completa disposizione.</div>
                  </div>
                  <div class="col-12">
                    <div class="success pt-2 pr-4 pl-4 pb-2 m-0">Cordiali saluti, <br>
                      Consulbrokers Spa</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Home',
  components: {
  },
};
</script>

<style lang="scss" scoped>
@import "~@/style.scss";
h4{
  color: #A7ADB1 !important;
}
.success{
  font-size: 1rem !important;
  line-height: 1.5rem;
}
.text{
  text-align: justify;
  line-height: 1.35rem;
}
.box {
  display: flex;
  flex-direction: column;
}

.b {
  padding: 16px;
  margin-top: 2px;
  display: flex;
  flex-direction: column;
}

.box-1 {
  height: 60px;
  justify-content: center;
}

.box-2 {
  flex: 1;
  justify-content: start;
}
.small{
  line-height: 1.35rem;
}
select{
  width: 100%;
  padding: 0 16px;
  border-radius: 4px;
  font-size: 14px;
  text-align: left;
  height: 42px;
  border: 1px solid #CCCCCC;
  background: #FCFCFC;
  box-shadow: none;
  outline: none;
  option:disabled {
    color: black;
  }
}

</style>
