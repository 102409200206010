<template>
  <div class="box">
    <div class="b box-2">
      <div class="row h-100 no-gutters">
        <div class="col-12 h-100">
          <div class="row">
            <div class="col-12">
              <div class="pt-2 pr-4 pl-4">
                <input type="radio" v-model="data.radioGroup" value="1">
                <label class="ml-2 h4" :class="{'grey': data.radioGroup === '2'}">Rinnovo garanzia</label>
              </div>
            </div>
            <div class="col-12">
              <div class="pt-2 pr-4 pl-4">
                <input type="radio" v-model="data.radioGroup" value="2">
                <label class="ml-2 h4" :class="{'grey': data.radioGroup === '1'}">Attiva Postuma</label>
              </div>
            </div>
            <div class="col-12">
              <form @submit.prevent="send" class="row pr-4 pl-4 pt-1 pb-2">
                <div class="col-12 col-md-6 pt-3">
                  <app-input placeholder="Codifice Fiscale"
                             v-model="data.fiscalCode" required></app-input>
                </div>
                <div class="col-12 col-md-6 pt-3 pb-3">
                  <app-input placeholder="E-Mail" type="email"
                             v-model="data.email" required></app-input>
                </div>
                <div class="col-12 pt-1 pb-2" v-if="item.err">
                  <div class="center">
                    <span class="err" v-if="data.newSub">
                      La polizza è scaduta da oltre 30 giorni.
E' possibile rinnovare la polizza al costo di 150€ o sottoscrivere una nuova polizza con decorrenza massima di 30 giorni al costo di 100 euro <br>
                      <router-link to="home" style="color: #039be5 !important;">Stipula una nuova polizza</router-link>
                    </span>
                    <span class="err" v-else-if="data.postNewSub">
                      La polizza è scaduta da oltre 90 giorni e non è possibile effettuare la sottoscrizione automatica alla postuma, contattaci ai nostri recapiti per maggiori informazioni.
                    </span>
                    <span class="err" v-else>
                      Il suo codice fiscale non risulta essere registrato.
                      Per maggiori informazioni ci contatti direttamente.
                    </span>
                    <br>
                  </div>
                </div>

                <div class="row no-gutters" style="width: 100%">
                  <div id="paypal" class="hidden"></div>
                  <div class="col-12 col-md-12 pr-3 pl-3"
                       id="smart-button-container" :class="{'hide': !data.type}">
                    <div id="paypal-button-container"></div>
                  </div>
                  <div class="col-12 col-md-12 pr-3 pl-3 pt-1" v-if="!item.error"
                       :class="{'hidden': data.type}">
                    <button class="button pt-3 mt-1 mt-md-0" v-if="!data.postNewSub">
                      <h5 class="m-0" v-if="!item.loadingC"
                          @click="item.type = true, data.c = 1">Verifica i dati e paga con carta</h5>
                      <div class="spinner-grow"
                           style="width: 1rem; height: 1rem;" v-else role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    </button>
                  </div>
                  <div class="col-12 col-md-12 pr-3 pl-3 pt-1" v-if="!item.error && !data.postNewSub">
                    <button class="button pt-3 mt-1 mt-md-0" v-if="data.c === 0"  @click="test">
                      <h5 class="m-0" v-if="!item.loadingB"
                          > Verifica i dati e paga con bonifico</h5>
                      <div class="spinner-grow"
                           style="width: 1rem; height: 1rem;" v-else role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    </button>
                    <button class="button pt-3 mt-1 mt-md-0" v-else-if="data.c === 1" @click="item.type = false, data.bonificoNext = false">
                      <h5 class="m-0" v-if="!item.loadingB "
                          > Paga con bonifico</h5>
                      <div class="spinner-grow"
                           style="width: 1rem; height: 1rem;" v-else role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    </button>
                  </div>
                  <div class="col-12 col-md-12 pr-3 pl-3 pt-1" v-if="item.error">
                    <div class="button red pt-3 mt-1 mt-md-0">
                      <h5 class="m-0">
                        Per i maggiori di 80 anni si invita al contatto diretto
                        <br><br>
                        <a href="tel:+393773595366">+39 377 359 5366</a><br><br>
                        <a href="mailto:polizza.vaccinatori@consulbrokers.it">polizza.vaccinatori@consulbrokers.it</a> <br>
                      </h5>
                    </div>
                  </div>
                </div>
                <div class="col-12 small pt-2">
                  * Il pagamento con carta è effettuato senza commissioni
                </div>
                <div class="col-12 small pt-2">
                  <img src="@/assets/credit-card-icons.png" width="150">
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import firebase from 'firebase';
import axios from 'axios';
import router from '@/router';
import { watch, reactive } from 'vue';
import AppInput from '@/components/input.vue';

export default {
  name: 'Box',
  components: {
    AppInput,
  },
  setup() {
    const item = reactive({
      index: 0,
      consent: false,
      loadingB: false,
      loadingC: false,
      err: false,
      type: false,
      province: [],
      comuni: [],
      comuniB: [],
      zip: [],
      zipB: [],
      error: false
    });
    const data = reactive({
      firstName: '',
      radioGroup: '1',
      lastName: '',
      birthDate: '',
      fiscalCode: '',
      sex: '',
      decorrenza: '',
      toponimo: '',
      address: '',
      addressNumber: '',
      addressCity: '',
      addressProvince: '',
      addressZip: '',
      addressNation: '',
      birthProvince: '',
      birthCity: '',
      email: '',
      phone: '',
      province: '',
      albo: '',
      type: false,
      confirmed: false,
      declaration: true,
      newSub: false,
      postNewSub: false,
      newSub100: false,
      bonificoNext: false,
      c: 0
    });

    watch(() => data.fiscalCode, () => {
      data.type = null
      document.getElementById('paypal-button-container').innerHTML = "";
    });


    function sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    }
    function test() {
      item.type = false
    }
    async function send() {
      item.err = false;
      // loader button
      if (!item.type) {
        item.loadingB = true;
      } else {
        item.loadingC = true;
      }
      await sleep(500);
      // check fiscal code
      let newTable = true
      data.fiscalCode = data.fiscalCode.toUpperCase()
      // new collection
      let check = await firebase.firestore().collection('requests')
          .where('fiscalCode', '==', data.fiscalCode)
          .get();
      // check on old collection
      if (check.docs.length <= 0) {
        newTable = false
        check = await firebase.firestore().collection('clients')
            .where('fiscalCode', '==', data.fiscalCode)
            .get();
      }
      if (check.docs.length <= 0) {
        item.err = true;
        item.loadingB = false;
        item.loadingC = false;
        return;
      }
      // there is a record
      let d = check.docs[0].data()
      let valid_from = d.valid_from.toDate()
      Date.prototype.addDays = function (days) {
        const date = new Date(this.valueOf());
        date.setDate(date.getDate() + days);
        return date;
      };
      let today = new Date()
      // se this.data.radioGroup === '1' è un rinnovo sennò è una postuma
      if (this.data.radioGroup === '1'){

        let days = 365 + 30
        let r = valid_from.addDays(days)
        if (today < r) {
          console.log("Importo di 100 denari ", r)
        } else {
          console.log("Importo di 150 denari ", r)
          item.err = true
          data.newSub = true
          item.loadingC = false;
          if (data.c === 0) {
            data.c = data.c + 1
            item.loadingB = false;
            return
          }
        }
      }
      if (this.data.radioGroup === '2') {
        let days = 365 + 90
        console.log(days)
        let r = valid_from.addDays(days)
        if (today < r) {
          console.log("Importo di 100 denari ", r)
        } else {
          item.err = true
          data.postNewSub = true
          return
        }
      }

      /* eslint-disable no-param-reassign */
      if (item.type) {
        data.type = true;
        if (newTable) {
          const updated_at = firebase.firestore.Timestamp.fromDate(new Date());
          let record = check.docs[0].data()
          record.updated_at = updated_at
          record.contract = this.data.radioGroup === '1' ? "renewal-2023-waiting" : "postuma-2023-waiting"
          record.email = this.data.email
          firebase.firestore().collection('clients').add(record).then(( res) => {
            if (data.newSub100) {
              item.loadingC = false;
              document.getElementById('paypal').innerHTML = res.id;
              window.initPayPalButton();
            } else {
              item.loadingC = false
              document.getElementById('paypal').innerHTML = res.id;
              window.initPayPalButtonUpdate()
            }

          })
        } else {
          firebase.firestore().collection('clients').doc(check.docs[0].id).get().then((data) => {
            const updated_at = firebase.firestore.Timestamp.fromDate(new Date());
            data.ref.update({
              updated_at: updated_at,
              email: this.data.email,
              contract: this.data.radioGroup === '1' ? "renewal-2023-waiting" : "postuma-2023-waiting"
            }).then(() => {
              item.loadingC = false;
              document.getElementById('paypal').innerHTML = check.docs[0].id;
              window.initPayPalButton();
            })
          });
        }
      //  VERIFICA I DATI E PAGA CON BONIFICO
      } else {
        if (newTable) {
          const updated_at = firebase.firestore.Timestamp.fromDate(new Date());
          let record = check.docs[0].data()
          record.updated_at = updated_at
          record.valid_from = valid_from.addDays(365)
          record.contract = this.data.radioGroup === '1' ? "renewal-2023-to-confirm" : "postuma-2023-to-confirm"
          record.email = this.data.email
          firebase.firestore().collection('clients').add(record).then((data) => {
            router.push({ name: 'success' });
            item.loadingB = false;
          })

        } else {
          firebase.firestore().collection('clients').doc(check.docs[0].id).get().then((data) => {
            const updated_at = firebase.firestore.Timestamp.fromDate(new Date());
            data.ref.update({
              updated_at: updated_at,
              email: this.data.email,
              valid_from: valid_from.addDays(365),
              contract: this.data.radioGroup === '1' ? "renewal-2023-to-confirm" : "postuma-2023-to-confirm"
            }).then(() => {
              router.push({name: 'success'});
              item.loadingB = false;
            })
          });
        }
      }
    }
    return {
      test,
      send,
      data,
      item,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "~@/style.scss";
.text{
  text-align: justify;
  line-height: 1.35rem;
}
.red {
  cursor: default;
  height: inherit;
  background: #fda12d;
  border: 2px solid #fda12d;
}
.box {
  display: flex;
  flex-direction: column;
}
.hide{
  display: none;
}
.b {
  padding: 16px;
  margin-top: 2px;
  display: flex;
  flex-direction: column;
}

.box-1 {
  height: 60px;
  justify-content: center;
}

.box-2 {
  flex: 1;
  justify-content: start;
}
.small{
  line-height: 1.35rem;
}
.hidden{
  display: none;
}
select{
  width: 100%;
  padding: 0 16px;
  border-radius: 4px;
  font-size: 14px;
  text-align: left;
  height: 42px;
  border: 1px solid #CCCCCC;
  background: #FCFCFC;
  box-shadow: none;
  outline: none;
  option:disabled {
    color: black;
  }
}

</style>
